/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import Select from "react-select";
import { Button } from "@mui/material";
import { getHeaderConfig, TICKET_REPORT_STATUS } from "./helper";
import SiTable from "../../core/table";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import "./index.scss";
import { exportToExcel } from "../../utils/index";

export const lastMonthDate = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  const lastMonth = new Date(d).toISOString();
  return lastMonth;
};
const TicketReports = () => {
  const [reportData, setReportData] = useState({});
  const [status, setStatus] = useState({});
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
    status: null,
    statusLabel: "",
  });

  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });
  useEffect(() => {
    if ((reportData.results || []).length !== 0) {
      setShowDownloadBtn(true);
    } else {
      setShowDownloadBtn(false);
    }
  }, [reportData]);

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      status: filterAfterClickingOnApply.status,
      startDate: filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
        : null,
    };

    const response = await invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const Status =
          item?.status.slice(0, 1).toUpperCase() +
          item?.status.slice(1).toLowerCase();
        const {
          id,
          sla,
          reopened: Reopened,
          closed: Closed,
          resolveMessage,
          holdEscalations,
          dept,
          category,
          store,
          assignedTo,
          severity: Severity,
        } = item;
        return {
          "Ticket Id": id,
          Status,
          SLA: sla,
          Assignee: assignedTo?.name,
          Reopened: Reopened ? "Yes" : "No",
          Category: category?.name,
          Closed: Closed ? "Yes" : "No",
          "Resolved Message": resolveMessage,
          "Hold Escalations": holdEscalations ? "Yes" : "No",
          Department: dept?.name,
          Store: store?.name,
          Severity: Severity
            ? Severity.slice(0, 1).toUpperCase() +
              Severity.slice(1).toLowerCase()
            : "",
        };
      });
    }
    exportToExcel(results || [], filterAfterClickingOnApply.statusLabel);
  };

  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  // filtering reports
  const fetchDataHandler = (filter = {}, getDataForPreviouslyAppliedFilter) => {
    const params = {
      ...filter,
      status: status.filterPayload.status,
      startDate: dateRange.startDate
        ? new Date(dateRange.startDate).toUTCString()
        : null,
      endDate: dateRange.endDate
        ? new Date(dateRange.endDate).toUTCString()
        : null,
      sortBy: "-createdAt",
    };
    if (getDataForPreviouslyAppliedFilter) {
      params.status = filterAfterClickingOnApply.status;

      params.startDate = filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
        : null;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s">
          <div>
            <div>Status</div>
            <Select
              value={status}
              classNamePrefix="si-select"
              options={TICKET_REPORT_STATUS}
              onChange={(option) => {
                setStatus(option);
              }}
            />
          </div>
          <div>
            <DateRangePicker
              cleanable={false}
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
            />
          </div>
          <Button
            size="medium"
            disabled={Object.keys(status || {}).length === 0}
            variant="contained"
            onClick={() => {
              let filterDataAfterClickingOnApply = {
                statusLabel: status?.label,
              };
              if (status?.filterPayload?.status) {
                filterDataAfterClickingOnApply.status =
                  status?.filterPayload?.status;
              } else {
                filterDataAfterClickingOnApply.status = null;
              }
              filterDataAfterClickingOnApply = {
                ...filterDataAfterClickingOnApply,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
              };
              setFilters((prevFilter) => ({ ...prevFilter, page: 1 }));
              setFilterAfterClickingOnApply(filterDataAfterClickingOnApply);
              fetchDataHandler({ ...filters, page: 1 });
            }}
          >
            Apply
          </Button>
        </div>
        {showDownloadBtn && (
          <Button
            size="large"
            variant="contained"
            onClick={downloadReport}
            disabled={(reportData.results || []).length === 0}
          >
            Download reports
          </Button>
        )}
      </div>
      <SiTable
        header={getHeaderConfig()}
        data={reportData.results || []}
        filters={filters}
        pageCount={reportData.totalPages}
        onChange={(e, page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></SiTable>
      {!status.value && (
        <span className="user-info-msg">
          Please select the status to download reports
        </span>
      )}
    </div>
  );
};

export default TicketReports;
