import React, { useEffect, useState, useCallback } from "react";
import "./index.scss";
import BasicInfo from "./basic-info";
import TimeLineInfo from "./timeline-info";
import FormDetails from "./form-details";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { useParams } from "react-router";
import ViewTicket from "../Ticket/view-ticket";
import { Drawer } from "@mui/material";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";

export const TicketDetails = () => {
  const { id } = useParams();
  const [timeline, setTimeline] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({});
  const [viewComplaint, setViewComplaint] = useState(null);
  const [complaintForm, setComplaintForm] = useState({});
  const [codes] = useState([]);
  const loadData = useCallback(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.TICKET}/${id}`, null)
      .then((response) => {
        if (response.code === 404) {
          toast.error("Invalid ticket");
          return;
        }
        const properResponse =
          response && Array.isArray(response) && response.length > 0;
        if (properResponse) {
          const ticketDetails = response[response.length - 1];
          setTimeline(response);
          if (ticketDetails.ticket) {
            setTicketDetails({
              ...ticketDetails,
              createdAt: response[0].createdAt,
            });
          }
        }
      })
      .catch((err) => console.log(err));

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.TICKETCOMPLAINTDETAILS}/${id}`
    )
      .then((response) => {
        if (response?.message) {
          return;
        }
        setComplaintForm({
          category: response.complaintForm.category.name,
          department: response.complaintForm.dept.name,
          store: response.complaintForm.store.name,
          id: response.complaintForm.id,
          details: response.complaintForm.details,
        });
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <div className="left-section">
        <BasicInfo ticketDetails={ticketDetails} onDateChange={loadData} />
      </div>

      <div className="ticket-details-container">
        <Grid container md={12} sm={12} className="grid-con">
          <Grid item md={7} sm={12}>
            <div className="middle-section">
              <TimeLineInfo
                ticketDetails={ticketDetails}
                timeline={timeline}
                openForm={() => setViewComplaint(true)}
                onDateChange={loadData}
                loadData={loadData}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <div className="right-section">
              <FormDetails
                loadData={loadData}
                codes={codes}
                ticketDetails={ticketDetails}
              />
            </div>
          </Grid>
        </Grid>
        {viewComplaint && (
          <Drawer anchor="right" open={!!viewComplaint}>
            <ViewTicket
              details={complaintForm || {}}
              onClose={() => setViewComplaint(null)}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};
