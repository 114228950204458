import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import "./index.scss";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { setOfflineData, getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import CustomModal from "../../core/modal";
export const Login = ({ setShowLogin }) => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({});

  useEffect(() => {
    if (getOfflineData("user")) {
      navigate("/tickets");
    }
  }, [navigate]);

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const login = () => {
    let payload = {
      email: loginDetails.email,
      password: loginDetails.password,
    };
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.LOGIN}`, payload)
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
          setLoginDetails({
            ...loginDetails,
            errorMsg: response.message,
          });
        } else {
          response.user && setOfflineData("user", response.user);
          response.tokens && setOfflineData("tokens", response.tokens);
          if (response.user.type == "STORE_OWNER") {
            navigate("/tickets");
          } else {
            navigate("/");
          }
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div>
        <CustomModal
          onClose={() => {
            setShowLogin(false);
          }}
          modalBoxClass={true}
        >
          <div className="login-form">
            <div className="title">Login</div>
            <TextField
              size="small"
              label="Email"
              name="email"
              variant="standard"
              value={loginDetails.email}
              onChange={onInputChange}
            />
            <TextField
              size="small"
              label="Password"
              type="password"
              name="password"
              variant="standard"
              value={loginDetails.password}
              onChange={onInputChange}
            />
            <div className="btn-con">
              <Button
                variant="contained"
                color="primary"
                onClick={login}
                disabled={!loginDetails.email || !loginDetails.password}
              >
                Login
              </Button>
            </div>
            {loginDetails.errorMsg && (
              <span className="error-msg">{loginDetails.errorMsg}</span>
            )}
          </div>
        </CustomModal>
      </div>
    </div>
  );
};
