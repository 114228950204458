import ActionIcons from "../actionIcons";
import { userTypes } from "../../utils/constants";
import { getOfflineData } from "../../utils/offline-services";
import { selectStoreTypeMap } from "../Store/config";

export const getHeaderConfig = (loadData) => {
  const allValuesMap = selectStoreTypeMap()
  const headerConfig = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Business Type",
      key: "allowedStoreTypes",
      render:(data) => {
        let allStoreTypeValues = []
        data?.allowedStoreTypes?.forEach(item => {
          const frontEndLabelForStoreType = allValuesMap[item]
          if (frontEndLabelForStoreType) {
            allStoreTypeValues.push(frontEndLabelForStoreType)
          }
        });
        allStoreTypeValues = allStoreTypeValues.join(", ")
        return <div className="si-cell">
          {allStoreTypeValues}
        </div>
      }
    },
    {
      label: "Actions",
      key: "actions",
      render: (data) => {
        return (
          <ActionIcons
            type={data.allowedStoreTypes}
            modalType="DEPARTMENT"
            loadData={loadData}
            id={data.id}
            name={data.name}
          />
        );
      },
    },
  ];
  let type = userTypes.STORE_OWNER;
  const usercredentails = getOfflineData("user");
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type;
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop();
  }
  return headerConfig;
};
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Business Type",
    key: "allowedStoreTypes",
  },
];
