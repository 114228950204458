import React, { useState, useEffect } from "react";
import "./index.scss";
import BlogScreen from "./blogScreen";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { REST_URLS, HOSTNAME, SUBWAY_BLOGS } from "../../utils/endpoints";
import Button from "@mui/material/Button";
import { formatDate } from "../../utils";
import SubwayLogo from "../../resources/info.png";
import { TextField } from "@mui/material";
import { BellCorpStudioLogoContainer } from "../Bellcorp-Studio-Logo";
import Header from "./header";
import { toast } from "react-toastify";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const Blogs = () => {
  const [editorBlogs, seteditorBlogs] = useState({});
  const [subwayNewBlogs, setsubwayNewBlogs] = useState({});

  const goToLinkOnClick = (link) => {
    window.open(link, "_blank");
  };

  const allFAQS = [
    {
      img: "https://hs-marketing-contentful.imgix.net/https%3A%2F%2Fimages.ctfassets.net%2Fp15sglj92v6o%2F3LT5c22mdWAiPdVLC14ati%2F8d9c605d824fd83900a8b9d4ba03d425%2Fhelp-desk-software.png?ixlib=gatsbySourceUrl-2.0.2&auto=format%2C%20compress&w=2248&h=1260&s=fb108a5d35260ac9712e341cf8fa72e5",
      text: "How to create a new tech net user?",
    },
    {
      img: "https://hs-marketing-contentful.imgix.net/https%3A%2F%2Fimages.ctfassets.net%2Fp15sglj92v6o%2F1fGDH8etUhdFNt833uE7Pu%2F93c620fd2a17f13bffd6134c134cb8b8%2Fthe-supportive-2.png?ixlib=gatsbySourceUrl-2.0.2&auto=format%2C%20compress&w=1247&h=703&s=ddb018af911af5b5f5f384080238dc8e",
      text: "How to get reports of tickets?",
    },
    {
      img: "https://hs-marketing-contentful.imgix.net/https%3A%2F%2Fimages.ctfassets.net%2Fp15sglj92v6o%2F3g3me7TUsf7D75ninpLtOj%2F6d5acd0ed41368b709ef5b3fb384018b%2FShared_Inbox.png?ixlib=gatsbySourceUrl-2.0.2&auto=format%2C%20compress&w=1247&h=700&s=ae5842c85ca70caf5509d63e7fb5f36e",
      text: "Who can access tech-net for supporting?",
    },
    {
      img: "https://hs-marketing-contentful.imgix.net/https%3A%2F%2Fimages.ctfassets.net%2Fp15sglj92v6o%2F3LT5c22mdWAiPdVLC14ati%2F8d9c605d824fd83900a8b9d4ba03d425%2Fhelp-desk-software.png?ixlib=gatsbySourceUrl-2.0.2&auto=format%2C%20compress&w=2248&h=1260&s=fb108a5d35260ac9712e341cf8fa72e5",
      text: "How to manage user credentials and users?",
    },
  ];

  const getBlogsByFilter = (filter = {}, { editPicker, subwayNews }) => {
    const params = {
      page: 1,
      limit: 10,
      sortBy: "-createdAt",
      ...filter,
    };

    invokeApi(
      HTTP_METHODS.GET,
      `${SUBWAY_BLOGS}${REST_URLS.GET_BLOGS}`,
      null,
      params
    )
      .then((response) => {
        const results = response?.results?.map((item) => {
          const {
            body,
            title,
            createdBy,
            createdAt,
            attachments = [],
            link,
          } = item;
          let finalAttachments = attachments;
          if (!attachments || !attachments.length || attachments.length === 0) {
            finalAttachments = [SubwayLogo];
          }
          const formattedDate = formatDate(createdAt, "dateString");
          return {
            images: finalAttachments,
            description: body,
            title,
            createdBy: createdBy?.name,
            createdOn: formattedDate,
            link,
          };
        });
        if (editPicker) {
          seteditorBlogs({ ...response, results });
        }
        if (subwayNews) {
          setsubwayNewBlogs({ ...response, results });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to load blogs", { autoClose: 2000 });
      });
  };

  //Api for getting blogs data

  // useEffect(() => {
  //   getBlogsByFilter(
  //     { category: "9672518d-e986-4831-a383-0f1ee78da10f" },
  //     { editPicker: true }
  //   );

  //   getBlogsByFilter(
  //     {
  //       category: "01d84e8a-90d8-4c59-b3e9-604fddf0f3ae",
  //     },
  //     { subwayNews: true }
  //   );
  // }, []);

  return (
    <div style={{ overflow: "scroll", height: "100vh" }}>
      <Header />
      <BlogScreen />
      <div className="commonFooterContainer">
        <div className="aboutUs">
          <div>
            <div>
              <h1>About Subway® Restaurants</h1>
            </div>
            <div className="about-us-content">
              <div>
                <div>
                  As one of the world's largest quick service restaurant brands,
                  Subway serves freshly made-to-order sandwiches, wraps, salads
                  and bowls to millions of guests, across more than 100
                  countries in more than 37,000 restaurants every day. Subway
                  restaurants are owned and operated by Subway franchisees – a
                  network that includes more than 20,000 dedicated entrepreneurs
                  and small business owners – who are committed to delivering
                  the best guest experience possible in their local communities.
                  <p>
                    Subway® is a Registered Trademark of Subway IP LLC. © 2022
                    Subway IP LLC
                  </p>
                </div>

                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="learn-More-Button"
                  >
                    <a
                      target="_blank"
                      href="https://newsroom.subway.com/about-Subway"
                    >
                      Learn More About us
                    </a>
                  </Button>
                </div>
              </div>

              <div>
                <img
                  src="https://filecache.mediaroom.com/mr5mr_subway/177336/Subway%20-%20About%20Us.png"
                  alt="subway-about-use-logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main-edit-con">
          <div className="edit-con ">
            <div className="edit-section">
              {/* <h2>Editor's Pick</h2>
              {(editorBlogs.results || []).map((item, index) => {
                return (
                  <div className="edit-grid" key={index}>
                    <div>
                      <img
                        onClick={() => goToLinkOnClick(item.link)}
                        className="editor-pick-image"
                        src={item.images[0]}
                        alt="Editor Image"
                      />
                    </div>
                    <div
                      onClick={() => goToLinkOnClick(item.link)}
                      className="editor-text"
                    >
                      <h3 className="title">{item?.title}</h3>
                    </div>
                  </div>
                );
              })} */}
              <h2>FAQs</h2>
              <div className="main-faq-container">
                {allFAQS.map((item, index) => {
                  return (
                    <div className="faq-container" key={index}>
                      <div>
                        <img src={item.img} className="faq-img" />
                      </div>
                      <div className="main-text"> 
                        <span>{item.text}</span>
                      </div>
                      <div>
                        <ArrowOutwardIcon />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="subscribe-customer-con">
            <div>
              <div className="subscribe-customer-con-image">
                <img
                  src="https://hs-marketing.imgix.net/images/pages/blog/PostsLanding/SubscribeCallout--customer-service.png?ixlib=gatsbyHook-2.0.2&fit=min&auto=format%2Ccompress&placeholder=dominantColor&w=244&h=171"
                  alt="Subscribe Image"
                />
              </div>
              <div>
                <h3>Subscribe to the Customer Service blog</h3>
                <p>
                  Join 251,101 readers who are obsessed with building a
                  customer-centric company.
                </p>
              </div>
              <div>
                <TextField
                  className="subcribe-text-field"
                  size="small"
                  label="Email Address"
                  type="text"
                />
                <Button variant="contained" color="primary" size="large">
                  Subscribe
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="news-con">
          <div className="news-section">
            <h1>Subway in the News</h1>
            {(subwayNewBlogs.results || []).map((item, index) => {
              return (
                <div className="news-item-container" key={index}>
                  <div
                    onClick={() => goToLinkOnClick(item.link)}
                    className="editor-text"
                  >
                    {item?.title}
                  </div>
                  <div>
                    <h3>{item?.description}</h3>
                  </div>
                  <div> {item?.createdOn}</div>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <BellCorpStudioLogoContainer />
    </div>
  );
};

export default Blogs;
