/** @format */

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button } from "@mui/material";
import { getSLAHeaderConfig } from "./helper";
import SiTable from "../../core/table";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { DateRangePicker } from "rsuite";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { lastMonthDate } from "./tickets";
import "./index.scss";
import { exportToExcel } from "../../utils";

const SLA = () => {
  const [reportData, setReportData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });
  const [department, setDepartment] = useState({});
  const [departmentsList, setDepartmentsList] = useState({});
  const [categoryList, setCategoryList] = useState({});
  const [category, setCategory] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
    department: null,
    departmentLabel: "",
    category: null,
  });

  useEffect(() => {
    otherOptions();
  }, []);
  useEffect(() => {
    if (department.key) {
      getCategories();
    }
    setCategory({});
  }, [department]);
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const fetchDataHandler = (
    filter,
    setFiltersAccordingToPreviosulyAppliedFilters
  ) => {
    const params = {
      ...filter,
      dept: department.key,
      startDate: dateRange.startDate
        ? new Date(dateRange.startDate).toUTCString()
        : null,
      endDate: dateRange.endDate
        ? new Date(dateRange.endDate).toUTCString()
        : null,
      sortBy: "-createdAt",
    };
    if (category.key) params["category"] = category.key;

    if (setFiltersAccordingToPreviosulyAppliedFilters) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
        : null;
      params.dept = filterAfterClickingOnApply.department;
      if (
        filterAfterClickingOnApply.category ||
        filterAfterClickingOnApply.category === 0
      ) {
        params.category = filterAfterClickingOnApply.category;
      } else {
        delete params.category;
      }
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.SLA_REPORTS}`,
      null,
      params
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      dept: department.key,
      startDate: dateRange.startDate
        ? new Date(dateRange.startDate).toUTCString()
        : null,
      endDate: dateRange.endDate
        ? new Date(dateRange.endDate).toUTCString()
        : null,
    };

    params.startDate = filterAfterClickingOnApply.startDate
      ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
      : null;
    params.endDate = filterAfterClickingOnApply.endDate
      ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
      : null;
    params.dept = filterAfterClickingOnApply.department;
    if (
      filterAfterClickingOnApply.category ||
      filterAfterClickingOnApply.category === 0
    ) {
      params.category = filterAfterClickingOnApply.category;
    }
    let response = await invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.SLA_REPORTS}`,
      null,
      params
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const {
          // id: Id,
          level: Level,
          escalated: Escalated,
          dept,
          category,
          ticket,
          escalateTo,
          escalationEmails,
        } = item;
        let allEscalationEmails = "";
        if (escalationEmails && Array.isArray(escalationEmails)) {
          const checker = {};
          escalationEmails.forEach((item, index) => {
            if (item.email && checker[item.email] == undefined) {
              checker[item.email] = true;
            }
          });
          const length = Object.keys(checker).length;

          Object.keys(checker).map((item, index) => {
            if (index === length - 1) {
              allEscalationEmails += item;
            } else {
              allEscalationEmails += item + ",";
            }
          });
        }
        return {
          // Id,
          Level,
          Escalated: Escalated ? "Yes" : "No",
          Department: dept?.name,
          Category: category?.name,
          "Ticket Id": ticket,
          EscalateTo: escalateTo?.name,
          "Escalation Emails": allEscalationEmails,
        };
      });
    }

    exportToExcel(results || [], filterAfterClickingOnApply.departmentLabel);
  };
  const otherOptions = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`
    )
      .then((response) => {
        if (response) {
          setDepartmentsList(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategories = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_CATEGORY_DEPARTMENT_WISE}${department.key}`,
      null
    )
      .then((response) => {
        if (response) {
          setCategoryList(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s">
          <div>
            <div>Department</div>
            <Select
              value={department}
              classNamePrefix="si-select"
              options={departmentsList}
              onChange={(option) => {
                setDepartment((prevVal) => ({
                  ...prevVal,
                  key: option.id,
                  label: option.name,
                }));
              }}
            />
          </div>
          {department.key && (
            <div className="left-s">
              <div>
                <div>Category</div>
                <Select
                  value={category}
                  classNamePrefix="si-select"
                  options={categoryList}
                  onChange={(option) => {
                    setCategory((prevVal) => ({
                      ...prevVal,
                      key: option.id,
                      label: option.name,
                    }));
                  }}
                />
              </div>
              <div>
                <DateRangePicker
                  cleanable={false}
                  placeholder="Select Date Range"
                  onChange={dateRangeHandler}
                />
              </div>
              <Button
                size="medium"
                variant="contained"
                onClick={() => {
                  setFilterAfterClickingOnApply({
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    department: department.key,
                    departmentLabel: department.label,
                    category: category.key,
                  });
                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  fetchDataHandler({ ...filters, page: 1 });
                }}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
        {department.key && (
          <Button
            size="large"
            variant="contained"
            onClick={downloadReport}
            disabled={(reportData.results || []).length === 0}
          >
            Download reports
          </Button>
        )}
      </div>
      <SiTable
        header={getSLAHeaderConfig()}
        data={reportData.results || []}
        filters={filters}
        pageCount={reportData.totalPages}
        onChange={(e, page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></SiTable>
      {!department.key && (
        <span className="user-info-msg">
          Please select the Department to download reports
        </span>
      )}
    </div>
  );
};

export default SLA;
