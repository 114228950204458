/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishIcon from "@mui/icons-material/Publish";
import "./index.scss";
import { IconButton } from "@mui/material";
import { invokeUploadFile } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";

const SiFileUpload = ({
  title,
  clsName,
  url,
  callBack,
  id,
  multiple,
  filesLimit,
  setMultipleFilesFunction,
}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [multipleFilesList, setMultipleFilesList] = useState([]);
  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    fileUpload();
  };

  const onChange = (e) => {
    if (!multiple) {
      setFile(e.target.files[0]);
      return;
    }
    const newFilesToBeAdded = [];

    Array.prototype.forEach.call(e.target.files, (item) => {
      if (multipleFilesList.length < filesLimit) {
        if (item.size > 1000000) {
          toast.error("File size should not exceed 1MB")
        } else {
          newFilesToBeAdded.push(item);
        }
        
      }
    });
    setMultipleFilesList((prevfiles) => {
      if (setMultipleFilesFunction) {
        setMultipleFilesFunction([...prevfiles, ...newFilesToBeAdded]);
      }
      return [...prevfiles, ...newFilesToBeAdded];
    });
  };

  const removeFilesFromMultipleFileList = (index) => {
    const filesAfterRemoval = multipleFilesList.filter((_, itemIndex) => {
      return index !== itemIndex;
    });
    setMultipleFilesList(filesAfterRemoval);
    if (setMultipleFilesFunction) {
      setMultipleFilesFunction((prevfiles) => {
        return prevfiles.filter((_, itemIndex) => index !== itemIndex);
      });
    }
  };

  const UploadDocumentLabel = () => {
    return (
      <IconButton
        title="Attach file"
        size="small"
        style={{
          background: "#008952",
          color: "#fff",
          borderRadius: 0,
          cursor: "pointer",
        }}
      >
        <label
          htmlFor="file-upload"
          className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary upload-button"
        >
          Upload Documents
          <PublishIcon fontSize="small" />
        </label>
      </IconButton>
    );
  };

  const FileUploadInput = () => {
    return (
      <input
        id="file-upload"
        type="file"
        onChange={(event) => {
          onChange(event);
          event.target.value = null;
        }}
      />
    );
  };

  const fileUpload = () => {
    if (file.size > 1000000) {
      toast.error("File size should not exceed 1MB")
      return
    }
    const formData = new FormData();
    formData.append("upload", file);
    setUploadStatus("Uploading...");
    return invokeUploadFile(
      `${HOSTNAME}${REST_URLS.UPLOAD_ATTACHMENT_FILES}${id}`,
      formData
    )
      .then((response) => {
        if (response.status === 200) {
          toast.info("Uploaded Successfully");
          setFile(null);
          callBack && callBack();
          setUploadStatus("");
        } else {
          toast.error(
            (response.data && response.data.message) || "Upload Failed"
          );
          setUploadStatus("");
        }
      })
      .catch((err) => {
        setFile(null);
        setUploadStatus("");
        toast.error("Upload Failed");
      });
  };

  return (
    <div className="relativePostion">
      <form className={`spidle-upload-file ${clsName}`}>
        {multiple ? (
          <>
            {multipleFilesList.length < filesLimit && <UploadDocumentLabel />}
            <FileUploadInput />
            {multipleFilesList.map((item, index) => {
              return (
                <div key={index}>
                  <label
                    title={(file && file.name) || ""}
                    className="uploaded-file-name"
                  >
                    {(item && item.name) || ""}
                  </label>
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => removeFilesFromMultipleFileList(index)}
                      title="Clear"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </span>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {file === null ? (
              <UploadDocumentLabel />
            ) : (
              <label
                title={(file && file.name) || ""}
                className="uploaded-file-name"
              >
                {(file && file.name) || ""}
              </label>
            )}
            <FileUploadInput />
            {file !== null && (
              <span>
                <IconButton
                  color="primary"
                  onClick={onFormSubmit}
                  title="Upload"
                  disabled={uploadStatus}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => setFile(null)}
                  title="Clear"
                  disabled={uploadStatus}
                >
                  <HighlightOffIcon />
                </IconButton>
              </span>
            )}
            {uploadStatus}
          </>
        )}
      </form>
      <div className="absoluteWarningContainer">
        *Max upload file size is 1 MB.
      </div>
    </div>
  );
};

export default SiFileUpload;
