import React from "react";
import CustomBarChart from "./bar-chart";
import "./index.scss";
import CustomPiChart from "./pi-chart";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const DepartmentAndCategory = (props) => {
  const { byCategory, byDept } = props.data;
  let byCategoryCategory = [];
  byCategory?.forEach((iter) => {
    if (!byCategoryCategory.includes(iter.category)) {
      byCategoryCategory.push(iter.category);
    }
  });

  let byDeptCategory = [];
  byDept?.forEach((iter) => {
    if (!byDeptCategory.includes(iter.dept)) {
      byDeptCategory.push(iter.dept);
    }
  });

  let deptOpened = Array(byDeptCategory.length).fill(0);
  let deptResolved = Array(byDeptCategory.length).fill(0);
  byDept?.forEach((iter) => {
    if (iter.status === "OPENED") {
      deptOpened[byDeptCategory.indexOf(iter.dept)] = iter.count;
    } else {
      deptResolved[byDeptCategory.indexOf(iter.dept)] = iter.count;
    }
  });

  let catOpened = Array(byCategoryCategory.length).fill(0);
  let catResolved = Array(byCategoryCategory.length).fill(0);
  byCategory?.forEach((iter) => {
    if (iter.status === "OPENED") {
      catOpened[byCategoryCategory.indexOf(iter.category)] = iter.count;
    } else {
      catResolved[byCategoryCategory.indexOf(iter.category)] = iter.count;
    }
  });

  let deptSeries = [
    {
      name: "Opened",
      data: deptOpened,
    },
    {
      name: "Resolved",
      data: deptResolved,
    },
  ];

  let catSeries = [
    {
      name: "Opened",
      data: catOpened,
    },
    {
      name: "Resolved",
      data: catResolved,
    },
  ];

  let objCatOpened = byCategoryCategory?.map((iter, index) => {
    return {
      name: iter,
      count: catOpened[index],
    };
  });

  let objCatResolved = byCategoryCategory?.map((iter, index) => {
    return {
      name: iter,
      count: catResolved[index],
    };
  });

  let objDeptOpened = byDeptCategory?.map((iter, index) => {
    return {
      name: iter,
      count: deptOpened[index],
    };
  });

  let objDeptResolved = byDeptCategory?.map((iter, index) => {
    return {
      name: iter,
      count: deptResolved[index],
    };
  });

  let topDeptOpened = objDeptOpened
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  let topDeptResolved = objDeptResolved
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  let topCatOpened = objCatOpened.sort((a, b) => b.count - a.count).slice(0, 5);

  let topCatResolved = objCatResolved
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  const [catPie, setCatpie] = React.useState(topCatOpened);
  const [deptPie, setDeptpie] = React.useState(topDeptOpened);
  const [alignmentDept, setAlignmentDept] = React.useState("Opened");
  const handleChangeDept = (event, newAlignment) => {
    setAlignmentDept(newAlignment);
    if (newAlignment == "Opened") setDeptpie(topDeptOpened);
    else setDeptpie(topDeptResolved);
  };
  const [alignmentCat, setAlignmentCat] = React.useState("Opened");
  const handleChangeCat = (event, newAlignment) => {
    setAlignmentCat(newAlignment);
    if (newAlignment == "Opened") setCatpie(topCatOpened);
    else setCatpie(topCatResolved);
  };

  return (
    <div>
      <div className="custom-toggle p10">
        <div className="h0">
          <ToggleButtonGroup
            color="primary"
            value={alignmentCat}
            exclusive
            onChange={handleChangeCat}
            className="toggle-button si-hide-mobile"
          >
            <ToggleButton value="Opened">Opened</ToggleButton>
            <ToggleButton value="Resolved">Resolved</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="other-info-container">
          <CustomBarChart
            title={
              <>
                <spna>Category Wise Tickets Stats</spna>
                <ToggleButtonGroup
                  color="primary"
                  value={alignmentCat}
                  exclusive
                  onChange={handleChangeCat}
                  className="toggle-button si-hide-web"
                >
                  <ToggleButton value="Opened">Opened</ToggleButton>
                  <ToggleButton value="Resolved">Resolved</ToggleButton>
                </ToggleButtonGroup>
              </>
            }
            data={catSeries}
            category={byCategoryCategory}
            name={"Category"}
            stacked={true}
            disableDownload={true}
            empty={
              catSeries[0].data.length > 0 || catSeries[1].data.length > 0
                ? false
                : true
            }
          />
          <CustomPiChart
            title={"Top 5 Categories with max " + alignmentCat + " Tickets"}
            data={catPie}
            xDataKey={"name"}
            bDataKey={"count"}
          />
        </div>
        <br />
        <div className="h0">
          <ToggleButtonGroup
            color="primary"
            value={alignmentDept}
            exclusive
            onChange={handleChangeDept}
            className="toggle-button si-hide-mobile"
          >
            <ToggleButton value="Opened">Opened</ToggleButton>
            <ToggleButton value="Resolved">Resolved</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="other-info-container departmentAndCategoryContainer">
          <CustomBarChart
            title={
              <>
                <spna>Department Wise Ticket Stats</spna>
                <ToggleButtonGroup
                  color="primary"
                  value={alignmentCat}
                  exclusive
                  onChange={handleChangeCat}
                  className="toggle-button si-hide-web"
                >
                  <ToggleButton value="Opened">Opened</ToggleButton>
                  <ToggleButton value="Resolved">Resolved</ToggleButton>
                </ToggleButtonGroup>
              </>
            }
            data={deptSeries}
            category={byDeptCategory}
            name={"Department"}
            stacked={true}
            disableDownload={true}
            empty={
              deptSeries[0].data.length > 0 || deptSeries[1].data.length > 0
                ? false
                : true
            }
          />

          <CustomPiChart
            title={"Top 5 Departments with max " + alignmentDept + " Tickets"}
            data={deptPie}
            xDataKey={"name"}
            bDataKey={"count"}
          />
        </div>
      </div>
    </div>
  );
};
export default DepartmentAndCategory;
