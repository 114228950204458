import React, { useState, useEffect } from "react";
import CustomModal from "../../core/modal";
import "./index.scss";
import { useParams } from "react-router";
import { formatDate } from "../../utils";
import { DATE_FORMATS } from "../../utils/constants";
import { IconButton, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getOfflineData } from "../../utils/offline-services";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Select from "react-select";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
const BasicInfo = ({ ticketDetails, reloadData, onDateChange }) => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const user = getOfflineData("user");
  const [adminAndTechNetUsersList, setadminAndTechNetUsersList] = useState([]);
  const [engineer, setEngineer] = useState(null);
  const [reassign, setReassign] = useState(false);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.ADMINANDTECHNETUSERSLIST}`
    )
      .then((response) => {
        if (response?.message) {
          return;
        }
        const allTechNetAndAdminUsers = response.map((item) => {
          return { label: item.name, value: item.id };
        });
        setadminAndTechNetUsersList(allTechNetAndAdminUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reAssignEnginner = () => {
    setReassign(false);
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.REASSIGNENGINEER}/${id}`,
      { assignedTo: engineer.value }
    )
      .then((response) => {
        if (response.success === true) {
          toast.success("Engineer reassigned successfully");
          navigate("/tickets");
          loadData();
          if (onDateChange) {
            onDateChange();
          }
          reloadData();
        } else {
          toast.error("Sorry something went wrong");
        }
        setReassign(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Grid container md={12} xs={12} className="basic-info-container">
      <Grid md={user && user.type !== "STORE_OWNER" ? 2 : 3} xs={12}>
        <div className="inputs">
          <div className="info-section">
            <div>Ticket id: </div>
            <div className="value"> {id.substr(0, 8)}</div>
          </div>
          <div className="info-section">
            <span>Created At:</span>
            <div className="value">
              {formatDate(
                ticketDetails.createdAt,
                DATE_FORMATS["MMM DD YYYY hh:MM"]
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid md={user && user.type !== "STORE_OWNER" ? 2 : 3} xs={12}>
        <div className="inputs">
          <div className="info-section">
            <div>Ticket Status:</div>
            <div className="value">
              {ticketDetails &&
              ticketDetails.ticket &&
              ticketDetails.ticket.status
                ? ticketDetails.ticket.status
                : ""}
            </div>
          </div>

          <div className="info-section">
            <span>Department:</span>
            <div className="value">
              {ticketDetails &&
                ticketDetails.ticket &&
                `${ticketDetails.ticket.dept.name}`}
            </div>
          </div>
        </div>
      </Grid>
      <Grid md={user && user.type !== "STORE_OWNER" ? 2 : 3} xs={12}>
        <div className="inputs">
          <div className="info-section">
            <span>Category:</span>
            <div className="value">
              {ticketDetails &&
                ticketDetails.ticket &&
                `${ticketDetails.ticket.category.name}`}
            </div>
          </div>
          <div className="info-section">
            <span>SLA Expiry:</span>
            <div className="value">
              {ticketDetails &&
                ticketDetails.ticket &&
                `${formatDate(
                  ticketDetails.ticket.slaExpiry,
                  DATE_FORMATS["MMM DD YYYY hh:MM"]
                )}`}
            </div>
          </div>
        </div>
      </Grid>

      <div className="inputs">
        {user && user.type !== "STORE_OWNER" && (
          <>
            <Grid md={user && user.type !== "STORE_OWNER" && 12} xs={12}>
              <div className="info-section">
                <span>Support Engineer:</span>
                <div className="value space-between">
                  {ticketDetails &&
                    ticketDetails.ticket &&
                    ticketDetails.ticket.assignedTo &&
                    ticketDetails.ticket.assignedTo.name}
                  <IconButton
                    size="small"
                    fontSize="12"
                    onClick={() => setReassign(true)}
                  >
                    <EditIcon fontSize="12" color="primary" />
                  </IconButton>
                </div>
              </div>
            </Grid>
          </>
        )}

        {reassign && (
          <CustomModal title="Reassign" onClose={() => setReassign(false)}>
            <div className="reassign-container">
              <div>Support Engineer</div>
              <Select
                placeholder="Select Support Engineer"
                value={engineer || ""}
                classNamePrefix="si-select"
                options={adminAndTechNetUsersList}
                onChange={(value) => setEngineer(value)}
                styles={{
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#008952" : "white",
                    color: state.isFocused ? "white" : "black",
                    ":active": {
                      backgroundColor: state.isSelected ? "#008952" : "white",
                      color: state.isFocused ? "white" : "black",
                    },
                  }),
                }}
              />

              <Button
                fullWidth
                variant="contained"
                className="button-color"
                size="small"
                disabled={!(engineer && engineer.value)}
                onClick={reAssignEnginner}
              >
                Update details
              </Button>
            </div>
          </CustomModal>
        )}
      </div>
    </Grid>
  );
};

export default BasicInfo;
