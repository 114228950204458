import ActionIcons from "../actionIcons";
import EscalationMatrix from "../EscalationMatrix";
import {userTypes} from '../../utils/constants'
import {getOfflineData} from '../../utils/offline-services'
export const getHeaderConfig = (loadData, departmentList, adminAndTechNetUsersList, escalationToUsersList) => {
  const headerConfig = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Description",
      key: "desc",
    },
    {
      label: "Department",
      key: "dept",
    },
    {
      label: "EscalationMatrix",
      key: "escalationMatrix",
      render: (data) => {
        return <EscalationMatrix data={data.escalationMatrix} />;
      },
    },
    {
      label: "Actions",
      key: "actions",
      render: (data) => {
        return (
          <ActionIcons
            loadData={loadData}
            departmentList={departmentList}
            id={data.id}
            name={data.name}
            dept={data.dept}
            deptId={data.deptId}
            escList={data.escalationMatrix}
            fullDesc={data.fullDesc}
            modalType="CATEGORY"
            parent={data.parent}
            adminAndTechNetUsersList={adminAndTechNetUsersList}
            userPrimaryContact={data.primaryContact}
            userSecondaryContact={data.secondaryContact}
            userTertiaryContact={data.tertiaryContact}
            escalationToUsersList={escalationToUsersList}
          />
        );
      },
    },
  ];

  let type = userTypes.STORE_OWNER
  const usercredentails = getOfflineData("user")
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop()
  }
  
  return headerConfig
}
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Description",
    key: "desc",
  },
];
