import React, { useState } from "react";
import "./index.scss";
import Select from "react-select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button, TextField } from "@mui/material";
import { TICKET_SEVERITY } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import SiFileUpload from "../../core/file-uploader";
import CustomModal from "../../core/modal";
import { toast } from "react-toastify";
import { getOfflineData } from "../../utils//offline-services";

const FormDetails = ({ loadData, ticketDetails = {} }) => {
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const user = getOfflineData("user");

  const [openModal, setOpenModal] = useState(null);
  const [message, setMessage] = useState("");

  const updateTicketDetails = (payload) => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATETICKET}/${id}`,
      payload
    )
      .then((response) => {
        if (response?.message) {
          toast.error("Update Failed");
        } else {
          toast.success("Ticket updated successfully");
          loadData();
        }
      })
      .catch((err) => console.log(err));
  };

  const addComments = () => {
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.COMMENT}`, {
      ticketId: id,
      comment,
    })
      .then((response) => {
        if (response?.message) {
          toast.error("Update Failed");
        } else {
          setComment("");
          loadData();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="form-details-container">
      {user && user.type !== "STORE_OWNER" && (
        <div className="severity">
          <div>Ticket Severity</div>
          <Select
            value={TICKET_SEVERITY.find((t) => {
              let finalTicketValue = null;
              if (
                ticketDetails &&
                ticketDetails.ticket &&
                ticketDetails.ticket.severity
              ) {
                finalTicketValue = ticketDetails.ticket.severity;
              }
              return t.value === finalTicketValue;
            })}
            classNamePrefix="si-select"
            options={TICKET_SEVERITY}
            onChange={(value) => {
              updateTicketDetails({
                severity: value.value,
              });
            }}
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateTicketDetails({ holdEscalations: true })}
              size="small"
            >
              Hold Escalation
            </Button>
          </div>
        </div>
      )}

      <div className="action-form">
        <div className="comments-section">
          <TextareaAutosize
            minRows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{
              width: "99%",
            }}
          />
          <div className="add-comment-bts">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={addComments}
              disabled={!comment}
            >
              Add Comment
            </Button>
            <SiFileUpload id={id} callBack={loadData} />
          </div>
        </div>

        <div className="buttons-container">
          {((ticketDetails &&
            ticketDetails.ticket &&
            ticketDetails.ticket.status === "RESOLVED") ||
            (ticketDetails &&
              ticketDetails.ticket &&
              ticketDetails.ticket.status === "CLOSED")) && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                updateTicketDetails({
                  status: "REOPENED",
                })
              }
            >
              Reopen ticket
            </Button>
          )}

          {user && user.type !== "STORE_OWNER" && (
            <>
              {((ticketDetails &&
                ticketDetails.ticket &&
                ticketDetails.ticket.status === "OPENED") ||
                (ticketDetails &&
                  ticketDetails.ticket &&
                  ticketDetails.ticket.status === "REOPENED")) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setOpenModal(true)}
                >
                  Resolve Ticket
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={
                  (ticketDetails
                    ? ticketDetails.ticket
                      ? ticketDetails.ticket.status === "RESOLVED"
                      : false
                    : false) ||
                  (ticketDetails
                    ? ticketDetails.ticket
                      ? ticketDetails.ticket.status === "CLOSED"
                      : false
                    : false)
                }
                onClick={() => updateTicketDetails({ status: "CLOSED" })}
              >
                Close ticket
              </Button>
            </>
          )}
        </div>
      </div>
      {openModal && (
        <CustomModal
          title="Resolve ticket"
          onClose={() => {
            setOpenModal(false);
            setMessage("");
          }}
        >
          <div className="resolve-msg">
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              size="small"
              multiline
              rows={4}
              placeholder="Resolve message"
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={() => {
                setMessage("");
                setOpenModal(false);
                updateTicketDetails({
                  status: "RESOLVED",
                  resolveMessage: message,
                });
              }}
            >
              {ticketDetails.status === "RESOLVED" ||
              ticketDetails.status === "CLOSED"
                ? "Reopen ticket"
                : "Resolve ticket"}
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default FormDetails;
