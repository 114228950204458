export const HeaderConfig = [
    {
      label: "Ticket ID",
      key: "id",
      onClick: true,
      render: (data, onClick) => {
        return (
          <span className='clickable userClickable' onClick={() => onClick(data)}>
            {data.id.substr(0, 8)}
          </span>
        );
      }
    },
    {
      label: "Created On",
      key: "createdAt",
      render: data => {
        return (
          <span>
            {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{" "}
            {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
          </span>
        );
      }
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      render: data => {
        let assignedTo = "Not assigned"
        if (data.assignedTo && data.assignedTo.name) {
          assignedTo = data.assignedTo.name
        }
        return <span>{assignedTo}</span>;
      }
    },
    {
      label: "Status",
      key: "status"
    }
  ];
  