import ActionIcons from "../actionIcons";
import { getOfflineData } from "../../utils/offline-services";
import { userTypes } from "../../utils/constants";
export const getHeaderConfig = (
  loadData,
  deptList,
  getAllDepartmentNamesByStoreType
) => {
  const headerConfig = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Email",
      key: "useremail",
    },
    {
      label: "Business Type",
      key: "type",
      render: (data) => {
        return (
          <div key={Math.random()}>{data?.type?.split("_")?.join(" ")}</div>
        );
      },
    },
    {
      label: "Owner",
      key: "owner",
    },
    {
      label: "Actions",
      key: "actions",
      render: (data) => {
        let selectedDeptList;
        if (data.departments) {
          selectedDeptList = data.departments.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
        } else {
          selectedDeptList = [];
        }

        return (
          <ActionIcons
            type={data.type}
            loadData={loadData}
            id={data.id}
            name={data.name}
            email={data.email}
            departmentList={deptList}
            selectedDepartments={selectedDeptList}
            owner={data.owner}
            ownerId={data.ownerId}
            getAllDepartmentNamesByStoreType={getAllDepartmentNamesByStoreType}
            modalType="STORE"
          />
        );
      },
    },
  ];

  let type = userTypes.STORE_OWNER;
  const usercredentails = getOfflineData("user");
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type;
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop();
  }
  return headerConfig;
};
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Email",
    key: "useremail",
  },
  {
    label: "Business Type",
    key: "type",
  },
  {
    label: "Owner",
    key: "owner",
  },
];
export const selectStoreType = [
  { label: "FOFO", value: "FOFO" },
  { label: "COCO BUILT", value: "COCO_BUILT" },
  { label: "COCO ACQUIRED", value: "COCO_ACQUIRED" },
  { label: "ALL", value: "ALL" },
];

export const storeTypeList = selectStoreType.map((item) => {
  return item.value;
});

export const selectStoreTypeMap = () => {
  const backendToFrontEndMapping = {};
  selectStoreType.forEach((item) => {
    backendToFrontEndMapping[item.value] = item.label;
  });
  return backendToFrontEndMapping;
};
