import React, { useState, useEffect } from "react";
import CustomBarChart from "./bar-chart";
import "./index.scss";
import CustomPiChart from "./pi-chart";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";


const Store = (props) => {
  const [storeIdMap, setStoreIdMap] = useState({});
  useEffect(() => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.LIST_ALL_STORE_NAMES}`)
      .then((response) => {
        if (response && Array.isArray(response)) {
          const storeMap = {};
          response.forEach((item) => {
            storeMap[item.id] = item.name;
          });
          setStoreIdMap(storeMap);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let storeMap = {};
  if (props && props.data) {
    props.data.forEach((item) => {
      const { count, storeName } = item;
      storeMap[item.storeId] = { count, storeName };
    });
  }

  let data = Object.keys(storeIdMap).map((item) => {
    if (storeMap[item] !== undefined) {
      const { count, storeName } = storeMap[item];
      return {
        name: storeName,
        count,
      };
    }
    return { count: 0, name: storeIdMap[item] };
  });

  let topData = data.sort((a, b) => b.count - a.count).slice(0, 5);

  return (
    <div className="p10 storeDashboardContainer" style={{ width: "97%" }}>
      <CustomBarChart
        title={"Store-wise Tickets Raises"}
        data={[
          {
            name: "Count",
            data: data.map((iter) => iter.count),
            // data: Array(700)
            //   .fill()
            //   .map((_, i) => {
            //     return i % 50 === 0 ? 20 : 0;
            //   }),
          },
        ]}
        category={data.map((iter) => iter.name)}
        // category={Array(700)
        //   .fill()
        //   .map((_, i) => i + "_name")}
        name={"Store"}
        stacked={false}
        disableDownload={false}
        empty={data.length > 0 ? false : true}
        dataLabelDisable={true}
      />
      <br />
      <CustomPiChart
        title={"Top Stores with Max Tickets Raises"}
        data={topData}
        xDataKey={"name"}
        bDataKey={"count"}
      />
   
    </div>
  );
};
export default Store;
