/** @format */

import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import SiTable from "../../core/table";
import { useNavigate } from "react-router-dom";
import { getOfflineData } from "../../utils/offline-services";
import {
  getHeaderConfig,
  getMobileHeaderConfig,
  selectStoreType,
} from "./config";
import Button from "@mui/material/Button";
import CustomModal from "../../core/modal";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Select from "react-select";
import { toast } from "react-toastify";
import { userTypes } from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
const Store = () => {
  const [store, setStore] = useState({});
  const [addStore, setAddStore] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [owner, setOwner] = useState({ label: "", value: null });
  const [ownerList, setOwnerList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [createStoreOption, showCreateStoreOption] = useState(true);
  const [storeName, setStoreName] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });

  const loadData = useCallback(
    (params) => {
      const isParamsValid = typeof params === "object" && params !== null
      const filterParams = isParamsValid ? { ...params } : { ...filters };
        const maxLen = 20;
      if (!filterParams.limit) {
        params.limit = filters.limit;
      }
      if (!filterParams.page) {
        params.page = filters.page;
      }
      if (!filterParams.sortBy) {
        params.sortBy = filters.sortBy;
      }
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.LIST_STORES}`,
        null,
        filterParams
      )
        .then((response) => {
          if (response) {
            const results = response.results.map((ele) => ({
              ...ele,
              owner: ele.owner.name,
              ownerId: ele.owner.id,
              useremail:
                ele.email.length > maxLen
                  ? `${ele.email.substring(0, maxLen)}...`
                  : ele.email,
              ownername:
                ele.owner.name > maxLen
                  ? `${ele.owner.name.substring(0, maxLen)}...`
                  : ele.owner.name,
            }));

            setStore({ ...response, results });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [filters]
  );

  const navigate = useNavigate();
  useEffect(() => {
    const userCredentials = getOfflineData("user");
    if (!userCredentials) {
      navigate("/login");
    } else {
      const { type } = userCredentials;
      if (type === userTypes.STORE_OWNER) {
        showCreateStoreOption(false);
      } else {
        showCreateStoreOption(true);
      }
      loadData(filters);
    }
  }, [filters, navigate, loadData]);

  const getAllDepartmentNamesByStoreType = (storeType) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`,
      null,
      { storeType }
    )
      .then((response) => {
        if (response) {
          response = response.map((user) => ({
            label: user.name,
            value: user.id,
          }));
          setDeptList(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_USERS_WITH_FILTER}`,
      null,
      { type: userTypes.STORE_OWNER }
    )
      .then((response) => {
        if (response) {
          response = response.map((user) => ({
            label: user.name,
            value: user.id,
          }));
          setOwnerList(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const addStoreFormHandler = () => {
    setAddStore(true);
  };
  const addNameHandler = (event) => {
    setName(event.target.value);
  };
  const addEmailHandler = (event) => {
    setEmail(event.target.value);
  };
  const addStoreTypeHandler = (event) => {
    getAllDepartmentNamesByStoreType(event.value);
    setType(event.value);
  };
  const addOwnerHandler = (event) => {
    setOwner(event);
  };
  const payload = {
    type: type,
    name: name,
    email: email,
    owner: owner.value,
    departments: selectedDept.map((dept) => dept.value),
  };

  //Add New Store
  const formSubmitHandler = () => {
    if (
      type === "" ||
      name === "" ||
      email === "" ||
      owner.value === "" ||
      selectedDept.length === 0
    ) {
      toast.error("Empty Fields Not Allowed", { autoClose: 2000 });
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.CREATE_STORE}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message);
          } else {
            toast.success("Store created successfully");
            loadData(filters);
            setName("");
            setEmail("");
            setType("");
            setOwner("");
            setAddStore(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="store-container">
        <div className="button-div mainsearchfield">
          <div className="search-field">
            <TextField
              fullWidth
              sx={{ my: 1 }}
              label="Search by store name"
              onChange={(e) => setStoreName(e?.target?.value)}
              value={storeName}
              size="small"
            />

            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  storeName,
                }));
              }}
            />
          </div>
          {createStoreOption && (
            <Button
              variant="contained"
              className="button button-color"
              onClick={addStoreFormHandler}
            >
              Create Store
            </Button>
          )}
        </div>

        <div className="si-hide-mobile">
          <SiTable
            filters={filters}
            header={getHeaderConfig(
              loadData,
              deptList,
              getAllDepartmentNamesByStoreType
            )}
            data={store.results || []}
            pageCount={store.totalPages}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable>
        </div>
        <div className="si-hide-web">
          <SiTable
            header={getMobileHeaderConfig()}
            data={store.results || []}
            pageCount={store.totalPages}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable>
        </div>
      </div>
      {addStore && (
        <CustomModal
          title="New Store"
          contentClassName={{ headerBackgroundColor: "#008952" }}
          onClose={() => {
            setAddStore(false);
            setName("");
            setEmail("");
            setType("");
            setOwner("");
            setDeptList([]);
            setSelectedDept([]);
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              required
              id="name"
              sx={{ my: 1 }}
              label="Name"
              multiline
              onChange={addNameHandler}
              value={name}
              size="small"
            />
            <TextField
              fullWidth
              id="email"
              required
              sx={{ my: 1 }}
              label="Email"
              onChange={addEmailHandler}
              value={email}
              type="email"
              size="small"
            />
            <Select
              value={owner.label}
              options={ownerList}
              required
              maxMenuHeight={150}
              className="select-dropdown"
              onChange={addOwnerHandler}
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              placeholder={owner.label !== "" ? owner.label : "Owner"}
            />
            <Select
              value={selectStoreType.find((item) => item.value === type)}
              required
              className="select-dropdown"
              options={selectStoreType}
              onChange={addStoreTypeHandler}
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              placeholder={type !== "" ? type : "Type"}
            />
            <Select
              options={deptList}
              required
              maxMenuHeight={150}
              className="select-dropdown"
              isMulti
              onChange={(item) => setSelectedDept(item)}
              placeholder="Select Departments"
              isClearable={false}
            />
            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={formSubmitHandler}
                fullWidth
              >
                Create
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAddStore(false);
                  setName("");
                  setEmail("");
                  setType("");
                  setOwner("");
                  setSelectedDept([]);
                }}
                fullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default Store;
