import React from "react";
import CustomBarChart from "./bar-chart";
import "./index.scss";
import CustomPiChart from "./pi-chart";
const TimeRelated = (props) => {
  const { mttr, mttc } = props.data;
  let mttrData = mttr?.map((iter) => {
    return {
      name: iter.name,
      count: iter.ttr ? iter.ttr : 0,
    };
  });

  let mttcData = mttc?.map((iter) => {
    return {
      name: iter.name,
      count: iter.ttr ? iter.ttc : 0,
    };
  });

  let topMttrData = mttrData.sort((a, b) => b.count - a.count).slice(0, 10);

  let topMttcData = mttcData.sort((a, b) => b.count - a.count).slice(0, 10);

  return (
    <div className="p10">
      <div className="other-info-container">
        <CustomBarChart
          title={"Department-wise MTR"}
          data={[{ name: "Count", data: mttrData?.map((iter) => iter.count) }]}
          category={mttrData?.map((iter) => iter.name)}
          name={"Mean_Time_To_Resolve"}
          stacked={false}
          disableDownload={false}
          empty={mttrData?.length > 0 ? false : true}
        />
        <CustomPiChart
          title={"Top Performing Departments on MTR"}
          data={topMttrData}
          xDataKey={"name"}
          bDataKey={"count"}
        />
      </div>
      <br />
      <div className="other-info-container">
        <CustomBarChart
          title={"Department-wise MTC"}
          data={[{ name: "Count", data: mttcData?.map((iter) => iter.count) }]}
          category={mttcData?.map((iter) => iter.name)}
          name={"Mean_Time_To_Close"}
          stacked={false}
          disableDownload={false}
          empty={mttcData?.length > 0 ? false : true}
        />
        <CustomPiChart
          title={"Top Performing Departments on MTC"}
          data={topMttcData}
          xDataKey={"name"}
          bDataKey={"count"}
        />
      </div>
    </div>
  );
};
export default TimeRelated;
