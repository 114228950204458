/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import SiTable from "../../core/table";
import { useNavigate } from "react-router-dom";
import { getOfflineData } from "../../utils/offline-services";
import { convertedTypes } from "../../utils/constants";
import { userTypes } from "../../utils/constants";
import {
  convertedTypesToRequired,
  getHeaderConfig,
  getMobileHeaderConfig,
  selectType,
} from "./config";
import Button from "@mui/material/Button";
import CustomModal from "../../core/modal";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
const Users = () => {
  const [users, setUsers] = useState({});
  const [addUser, setAddUser] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [onCall, setOnCall] = useState(false);
  const [password, setPassword] = useState("");
  const [showOnCall, setShowOnCall] = useState(false);
  const [createStoreButton, setcreateStoreButton] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });
  const [dept, setDept] = useState({ label: "", value: null });
  const [departmentList, setDepartmentList] = useState([]);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const usercredentails = getOfflineData("user");
    if (!usercredentails) {
      navigate("/login");
    } else {
      const { type } = usercredentails;
      if (type === userTypes.STORE_OWNER) {
        setcreateStoreButton(false);
      } else {
        setcreateStoreButton(true);
      }
      loadData(filters);
    }
  }, [navigate, filters]);

  useEffect(() => {
    if (
      type === "TECH_NET_USER" ||
      type === "ADMIN" ||
      type === convertedTypes["ADMIN"] ||
      type === convertedTypes["TECH_NET_USER"]
    ) {
      setShowOnCall(true);
      setOnCall(false);
    } else {
      setShowOnCall(false);
      setOnCall(false);
    }
  }, [type]);

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENT_NAMES}`
    )
      .then((response) => {
        if (response) {
          response = response.map((dept) => ({
            label: dept.name,
            value: dept.id,
          }));
        }
        setDepartmentList(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const setDeptHandler = (event) => {
    setDept((prevVal) => ({
      ...prevVal,
      label: event.label,
      value: event.value,
    }));
  };
  const addUserFormHandler = () => {
    setAddUser(true);
  };

  const addNameHandler = (event) => {
    setName(event.target.value);
  };
  const addEmailHandler = (event) => {
    setEmail(event.target.value);
  };
  const addStoreTypeHandler = (event) => {
    setType(event.label);
  };
  const addPasswordHandler = (event) => {
    setPassword(event.target.value);
  };
  const onCallHandler = (event) => {
    setOnCall(!onCall);
  };
  const payload = {
    type: convertedTypesToRequired[type],
    name: name,
    email: email,
    password: password,
    onCall: onCall,
  };

  //Add New User
  const formSubmitHandler = () => {
    if (
      (type === "TECH_NET_USER" && dept.value !== "") ||
      (type === convertedTypesToRequired["Tech Net User"] && dept.value !== "")
    ) {
      payload.dept = dept.value;
    }
    if (type === "" || name === "" || email === "" || password === "") {
      toast.error("Empty Fields not allowed", { autoclose: 2000 });
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.CREATE_USER}`,
        payload
      )
        .then((response) => {
          if (response.message) {
            toast.error(response.message);
          } else {
            toast.success("User created successfully");
            loadData(filters);
            setName("");
            setEmail("");
            setType("");
            setPassword("");
            setAddUser(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  //get users
  const loadData = (params) => {
    const allFilters = params ? { ...params } : { ...filters };
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_USERS}`,
      null,
      allFilters
    )
      .then((response) => {
        if (response) {
          const maxLen = 20;
          const results = response.results.map((ele) => {
            let type = null;
            if (ele.type) {
              type = convertedTypes[ele.type];
            }
            return {
              ...ele,
              onCall: ele.onCall ? "true" : "false",
              usertype: type,
              useremail:
                ele.email.length > maxLen
                  ? `${ele.email.substring(0, maxLen)}...`
                  : ele.email,
              username:
                ele?.name.length > maxLen
                  ? `${ele?.name.substring(0, maxLen)}...`
                  : ele.name,
            };
          });
          setUsers({ ...response, results });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="user-container">
        <div className="button-div">
          <div className="search-field">
            <TextField
              fullWidth
              sx={{ my: 1 }}
              label="Search by name"
              onChange={(e) => setUserName(e?.target?.value)}
              value={userName}
              size="small"
            />

            <SearchIcon
              className="main-search-icon"
              onClick={() => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  page: 1,
                  userName,
                }));
              }}
            />
          </div>
          <div>
            {createStoreButton && (
              <Button
                variant="contained"
                className="button"
                onClick={addUserFormHandler}
              >
                Create User
              </Button>
            )}
          </div>
        </div>

        <div className="si-hide-mobile">
          <SiTable
            header={getHeaderConfig(loadData, departmentList)}
            data={users.results || []}
            pageCount={users.totalPages}
            filters={filters}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable>
        </div>
        <div className="si-hide-web">
          <SiTable
            header={getMobileHeaderConfig(null, departmentList)}
            data={users.results || []}
            pageCount={users.totalPages}
            filters={filters}
            departmentList={departmentList}
            onChange={(event, page) => {
              setFilters({
                ...filters,
                page,
              });
              loadData({
                ...filters,
                page,
              });
            }}
          ></SiTable>
        </div>
      </div>
      {addUser && (
        <CustomModal
          title="New User"
          contentClassName={{ headerBackgroundColor: "#008952" }}
          onClose={() => {
            setAddUser(false);
            setDept({ label: "", value: null });
            setName("");
            setEmail("");
            setType("");
            setPassword("");
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="name"
              sx={{ my: 1 }}
              label="Name"
              multiline
              required
              onChange={addNameHandler}
              value={name}
              size="small"
            />
            <TextField
              fullWidth
              id="email"
              sx={{ my: 1 }}
              required
              label="Email"
              onChange={addEmailHandler}
              value={email}
              type="email"
              size="small"
            />

            <TextField
              fullWidth
              id="password"
              label="Password"
              required
              sx={{ my: 1 }}
              onChange={addPasswordHandler}
              value={password}
              type="password"
              size="small"
            />
            <Select
              value={type}
              required
              options={selectType}
              onChange={addStoreTypeHandler}
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              placeholder={type !== "" ? type : "Type"}
            />

            {(type === "TECH_NET_USER" ||
              type === convertedTypes["TECH_NET_USER"]) && (
              <Select
                value={dept.label}
                options={departmentList}
                maxMenuHeight={150}
                className="select-dropdown select-department"
                onChange={setDeptHandler}
                getOptionLabel={(op) => op.label}
                getOptionValue={(op) => op.value}
                placeholder={dept.label !== "" ? dept.label : "Department"}
              />
            )}

            {showOnCall && (
              <FormControlLabel
                label="On Call"
                control={<Checkbox checked={onCall} onChange={onCallHandler} />}
              />
            )}

            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={formSubmitHandler}
                fullWidth
              >
                Create
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAddUser(false);
                  setName("");
                  setEmail("");
                  setType("");
                  setPassword("");
                }}
                fullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default Users;
