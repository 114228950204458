/** @format */

export const REPORT_TABS = ["Tickets", "Support Person", "SLA", "Stores"];

export const TICKET_REPORT_STATUS = [
  {
    label: "Open Tickets",
    value: "OPENED",
    filterPayload: {
      status: "OPENED",
    },
  },
  {
    label: "Closed Tickets",
    value: "CLOSED",
    filterPayload: {
      status: "CLOSED",
    },
  },
  {
    label: "Reopend Tickets",
    value: "REOPENED",
    filterPayload: {
      status: "REOPENED",
    },
  },
  {
    label: "Resolved Tickets",
    value: "RESOLVED",
    filterPayload: {
      status: "RESOLVED",
    },
  },
];

export const getHeaderConfig = () => [
  {
    label: "Ticket ID",
    key: "id",
  },
  {
    label: "Created On",
    key: "createdAt",
    render: (data) => {
      return (
        <span>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{" "}
          {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: "Assigned to",
    key: "assignedTo",
    render: (data) => {
      return <div>{data.assignedTo.name}</div>;
    },
  },
  {
    label: "Status",
    key: "status",
  },
];
export const getSLAHeaderConfig = () => [
  {
    label: "Level",
    key: "level",
  },
  {
    label: "Created On",
    key: "createdAt",
    render: (data) => {
      return (
        <span>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}{" "}
          {data.createdAt && new Date(data.createdAt).toLocaleTimeString()}
        </span>
      );
    },
  },
  {
    label: "Department",
    key: "dept",
    render: (data) => {
      return <div>{data.dept.name}</div>;
    },
  },
  {
    label: "Category",
    key: "category",
    render: (data) => {
      return <div>{data.category.name}</div>;
    },
  },
  {
    label: "Ticket",
    key: "ticket",
  },
];
export const getStoreHeaderConfig = () => [
  {
    label: "Store Name",
    key: "Store Name",
  },
  {
    label: "Department",
    key: "Department",
  },
  {
    label: "Category",
    key: "Category",
  },
  {
    label: "Count",
    key: "Count",
    render: (data, onClick) => {
      return (
        <span onClick={() => onClick(data)} className="clickable userClickable">
          {data.Count}
        </span>
      );
    },
  },
];

export const getHeaderConfigForTableItems = () => [
  {
    label: "Id",
    key: "id",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Resolve Message",
    key: "resolveMessage",
  },
];
