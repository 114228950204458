import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import Dashboard from "../components/Dashboard";
import Setup from "../components/Setup";
import { PastTickets, Login, TicketDetails, CreateTicket } from "../components";
import Reports from "../components/reports";
import Blogs from "../components/blogs";

const AppRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Blogs />}></Route>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/setup" element={<Setup />} />
            <Route exact path="/tickets" element={<PastTickets />} />
            <Route exact path="/details/:id" element={<TicketDetails />} />
            <Route exact path="/create" element={<CreateTicket />} />
            <Route exact path="/reports" element={<Reports />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
