import React, { useState } from "react";
import { Button } from "@mui/material";
import { getStoreHeaderConfig, getHeaderConfigForTableItems } from "./helper";
import SiTable from "../../core/table";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { DateRangePicker } from "rsuite";
import "./index.scss";
import { lastMonthDate } from "./tickets";
import { exportToExcel } from "../../utils";
import CustomModal from "../../core/modal";

const Stores = () => {
  const [reportData, setReportData] = useState([]);
  const [tableItems, setTableItems] = useState(null);
  const [tableItemsDataSlicingIndex, settableItemsDataSlicingIndex] =
    useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });
  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };

  const fetchDataHandler = (
    providedFilters,
    getDataOnBasisOfPreviousFilters
  ) => {
    const dataFilters =
      typeof providedFilters === "object"
        ? { ...providedFilters }
        : { ...filters };
    const params = {
      startDate: dateRange.startDate
        ? new Date(dateRange.startDate).toUTCString()
        : null,
      endDate: dateRange.endDate
        ? new Date(dateRange.endDate).toUTCString()
        : null,
      ...dataFilters,
      sortBy: "-createdAt",
    };
    if (getDataOnBasisOfPreviousFilters) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
        : null;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.STORE_REPORTS}`,
      null,
      params
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      startDate: filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
        : null,
      sortBy: "-createdAt",
    };
    let response = await invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.STORE_REPORTS}`,
      null,
      params
    );
    let convertedResponse = [];
    if (response) {
      const processedResponse = [];
      response?.results?.forEach((item) => {
        const responseItem = {
          Category: item.Category,
          Count: item.Count,
          Department: item.Department,
          // "Store ID": item["Store ID"],
          "Store Name": item["Store Name"],
        };
        item["Ticket Data"].forEach((item) => {
          const ticketDataItem = {
            "Ticket Id": item.id,
            "Resolve Message": item.resolveMessage,
            Status:
              item?.status.slice(0, 1).toUpperCase() +
              item?.status.slice(1).toLowerCase(),
          };
          processedResponse.push({ ...responseItem, ...ticketDataItem });
        });
      });
      convertedResponse = processedResponse;
    }
    exportToExcel(convertedResponse || [], "Stores");
  };

  const onClickShowDataFunction = (data) => {
    if (data && data["Ticket Data"]) {
      setTableItems(data["Ticket Data"]);
    }
  };

  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s">
          <div>
            <DateRangePicker
              cleanable={false}
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
            />
          </div>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              setFilterAfterClickingOnApply({
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
              });
              setFilters((prevFilters) => ({...prevFilters, page:1}))
              fetchDataHandler({...filters, page:1});
            }}
          >
            Apply
          </Button>
        </div>

        <Button
          size="large"
          variant="contained"
          onClick={downloadReport}
          disabled={(reportData?.results || []).length === 0}
        >
          Download reports
        </Button>
      </div>
      <SiTable
        header={getStoreHeaderConfig()}
        data={reportData?.results || []}
        filters={filters}
        pageCount={reportData?.totalPages}
        onClick={onClickShowDataFunction}
        onChange={(e, page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler({ ...filters, page }, true);
        }}
      ></SiTable>
      {tableItems && (
        <CustomModal
          title="Ticket Details"
          onClose={() => {
            settableItemsDataSlicingIndex(0);
            setTableItems(null);
          }}
          contentClassName={{
            headerBackgroundColor: "#008952",
            customClass: "tableModal",
          }}
        >
          <div>
            <SiTable
              header={getHeaderConfigForTableItems()}
              filters={filters}
              data={
                tableItems?.slice(
                  tableItemsDataSlicingIndex * 10,
                  tableItemsDataSlicingIndex * 10 + 10
                ) || []
              }
              pageCount={Math.ceil(tableItems?.length / 10 || 0)}
              onChange={(_, page) => {
                settableItemsDataSlicingIndex(page - 1);
              }}
            ></SiTable>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default Stores;
