/** @format */

export const DATE_FORMATS = {
  "DD.MM.YYYY": "DD.MM.YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "DD.MM.YYYY hh:MM": "DD.MM.YYYY hh:MM",
  "DD MMM YY hh:MM": "DD MMM YY hh:MM",
  "MMM DD YYYY hh:MM": "MMM DD YYYY hh:MM",
  "YYYY-MM-DD": "YYYY-MM-DD",
};

export const TICKET_SEVERITY = [
  {
    label: "Minor",
    value: "MINOR",
  },
  {
    label: "Major",
    value: "MAJOR",
  },
];

export const EXTENDED_TECH_SUPPORT_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const TICKET_STAGES = {
  NO_STAGE: "UNASSIGNED",
  COMPLAINT_FORM_SUBMITTED: "COMPLAINT_FORM_SUBMITED",
  COMPLAINT_FORM_UPDATED: "COMPLAINT_FORM_UPDATED",
  ASSIGN_SE: "ASSIGNED",
  COMMENT: "COMMENT",
  TICKET_UPDATED: "TICKET_UPDATED",
  ATTACHMENT: "ATTACHMENT",
  SEVERITY: "TICKET_SEVERITY_UPDATE",
  STATUS_UPDATE: "TICKET_STATUS_UPDATE",
  REASSIGN: "REASSIGN",
  HOLD_ESCALATIONS: 'HOLD_ESCALATIONS',
};

export const userTypes = {
  STORE_OWNER: "STORE_OWNER",
  ADMIN: "ADMIN",
  TECH_NET_USER: "TECH_NET_USER",
};

export const convertedTypes = {
  STORE_OWNER: "Store Owner",
  ADMIN: "Admin",
  TECH_NET_USER: "Tech Net User",
};
