import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { REST_URLS, HOSTNAME, SUBWAY_BLOGS } from "../../utils/endpoints";
import SubwayLogo from "../../resources/info.png";
import { formatDate } from "../../utils";
import { toast } from "react-toastify";
const LatestArticle = ({ id }) => {
  const [blogs, setBlogs] = useState([]);
  const [recentPost, setRecentPost] = useState([]);
  const [otherPost, setOtherPost] = useState([]);
  useEffect(() => {
    if (id == "all") {
      getBlogs();
    } else if (id) {
      getBlogs(id);
    }
  }, [id]);
  useEffect(() => {
    if (blogs.length) {
      setRecentPost((prevVal) => [blogs[0]]);
      let recentTwo = blogs.slice(1, 3);
      setOtherPost(recentTwo);
    }
  }, [blogs]);
  const getBlogs = (categoryid) => {
    let params = {
      page: 1,
      limit: 50,
      sortBy: "-createdAt",
      excludeCategories: JSON.stringify([
        "9672518d-e986-4831-a383-0f1ee78da10f",
        "01d84e8a-90d8-4c59-b3e9-604fddf0f3ae",
      ]),
    };
    if (categoryid) {
      params.category = categoryid;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${SUBWAY_BLOGS}${REST_URLS.GET_BLOGS}`,
      null,
      params
    )
      .then((res) => {
        const results =
          res?.results?.map((item) => {
            const { createdAt, attachments = [] } = item;
            let finalAttachments = attachments;
            if (
              !attachments ||
              !attachments.length ||
              attachments.length === 0
            ) {
              finalAttachments = [SubwayLogo];
            }
            const formattedDate = formatDate(createdAt, "dateString");
            return {
              ...item,
              attachments: finalAttachments,
              createdAt: formattedDate,
            };
          }) || [];
        setBlogs(results);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to load blogs", { autoClose: 2000 });
      });
  };

  return (
    <div className="latest-article">
      <h1>
        {" "}
        {id === "af804a2d-5748-492d-a201-0e9a04437c2f"
          ? "Customer Service"
          : "Subway Newsletters and FAQs"}{" "}
      </h1>
      <p>
        {id === "af804a2d-5748-492d-a201-0e9a04437c2f"
          ? "Get tips and advice from experienced support pros on delivering great customer service, building a career in support, and leading a support team."
          : "Get updated with Subway’s newsletters, updates and launches. Gets tips and advice on how to manage and use the platform through our up-to-date FAQs and blogs."}
      </p>
      <div className="recent-post-con">
        <h3>Most Recent Posts</h3>
      </div>
      <div>
        <Grid container md={12}>
          <Grid md={8} xs={12}>
            {recentPost.map((post) => {
              return (
                <div className="post-card">
                  <img src={post.attachments[0]} />
                  <h3>{post.title}</h3>
                  <p>{post.body}</p>
                </div>
              );
            })}
          </Grid>
          <Grid md={4} xs={12}>
            {otherPost.map((post) => {
              return (
                <div className="post-card other-card">
                  <img src={post.attachments[0]} />
                  <h3>{post.title}</h3>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LatestArticle;
