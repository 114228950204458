import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import LatestArticle from "./latestArticle";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { REST_URLS, HOSTNAME, SUBWAY_BLOGS } from "../../utils/endpoints";
import { toast } from "react-toastify";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const BlogScreen = () => {
  const [value, setValue] = useState(0);
  const [allCategories, setallCategories] = useState([]);

  // Api for getting all the categories
  // useEffect(() => {
  //   invokeApi(
  //     HTTP_METHODS.GET,
  //     `${SUBWAY_BLOGS}${REST_URLS.BLOG_CATEGORIES}`,
  //     null,
  //     {
  //       excludeCategories: JSON.stringify([
  //         "9672518d-e986-4831-a383-0f1ee78da10f",
  //         "01d84e8a-90d8-4c59-b3e9-604fddf0f3ae",
  //       ]),
  //     }
  //   )
  //     .then((response) => {
  //       setallCategories(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Failed to load", { autoClose: 2000 });
  //     });
  // }, []);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="blog-screen">
      <Box sx={{ width: "92%", margin: "0 auto" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="white"
          aria-label="white"
        >
          <Tab {...a11yProps(0)} label="Latest Articles" className="tab" />
          {allCategories.map((item, index) => {
            return (
              <Tab
                key={index}
                {...a11yProps(index + 1)}
                label={item.name}
                className="tab"
              />
            );
          })}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LatestArticle id="all" />
      </TabPanel>

      {allCategories.length > 0 &&
        allCategories.map((item, index) => {
          return (
            <TabPanel value={value} index={index + 1}>
              <LatestArticle id={item?.id || null} />
            </TabPanel>
          );
        })}
    </div>
  );
};

export default BlogScreen;
