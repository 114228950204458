import { getOfflineData } from "../../utils/offline-services";
import { userTypes } from "../../utils/constants";
export const SIDE_MENU = () => {
  const user = getOfflineData("user");
  let menuOptions = [
    {
      label: "Dashboard",
      url: "/",
      value: "/",
    },
    {
      label: "Tickets",
      url: "/tickets",
      value: "/tickets",
    },
  ];
  if (user && user.type !== userTypes.STORE_OWNER) {
    menuOptions = [...menuOptions, {
      label: "Setup",
      url: "/setup",
      value: "/setup",
    }, {
      label: "Reports",
      url: "/reports",
      value: "/reports",
    }]
  }
  return menuOptions;
};
export const MOBILE_MENU = [
  {
    label: "Dashboard",
    url: "/",
    value: "/",
  },
  {
    label: "Tickets",
    url: "/tickets",
    value: "/tickets",
  },
  {
    label: "Setup",
    url: "/setup",
    value: "/setup",
  },
  {
    label: "Reports",
    url: "/reports",
    value: "/reports",
  },
];
