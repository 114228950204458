import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";

export const getAllTicketCategoriesForaParent = (
  parent,
  setDataFunction
) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.LIST_ALL_CATEGORY_NAMES}`,null,
    { parent }
  )
    .then((res) => {
        const noErrorOccurred = !res?.message
        if (noErrorOccurred && setDataFunction) {
            setDataFunction(
                res?.map((item) => {
                    return {label:item.name, value:item.id}
                }) || []
            )
        }
    })
    .catch((err) => {
      console.log(err);
    });
};
