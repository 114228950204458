import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import "./index.scss";
import SupportPerson from "./SupportPerson";
import Overview from "./Overview";
import { Button } from "@material-ui/core";
import Store from "./Store";
import SlaBreaches from "./SlaBreaches";
import TimeRelated from "./TimeRelated";
import DepartmentAndCategory from "./DepartmentAndCategory";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid } from "@material-ui/core";
import { getOfflineData } from "../../utils/offline-services";
import { userTypes } from "../../utils/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Dashboard = () => {
  const [dashboardDetails, setDashboardDetails] = useState({
    overview: {},
    deptAndCategory: {},
    store: [],
    mtt: {},
    sla: [],
    support: {},
  });
  const [datePickerstartDate, setdatePickerStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
  );
  const [datePickerendDate, setdatePickerEndDate] = useState(
    new Date().toISOString()
  );
  const [value, setValue] = useState(0);
  const [storeIndex, setstoreIndex] = useState(3);
  const [showSelectedTabs, setshowSelectedTabs] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [toggle, setToggle] = useState("Department-wise Escalation");
  const [showAllData, setshowAllData] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setDatePickerDate = (value) => {
    const newStartDate = value[0].toISOString();
    const newEndDate = value[1].toISOString();
    setdatePickerStartDate(newStartDate);
    setdatePickerEndDate(newEndDate);
  };

  const applyDatePickerDate = () => {
    setStartDate(datePickerstartDate);
    setEndDate(datePickerendDate);
  };

  const clearDateRangeFilter = () => {
    setdatePickerStartDate(
      new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      ).toISOString()
    );
    setdatePickerEndDate(new Date().toISOString());
  };

  useEffect(() => {
    const userCredentials = getOfflineData("user");
    if (userCredentials) {
      const { type } = userCredentials;
      if (type === userTypes.STORE_OWNER) {
        setstoreIndex(2);
        setshowSelectedTabs(true);
      } else {
        setstoreIndex(3);
        setshowSelectedTabs(false);
      }
    }
  }, []);

  useEffect(() => {
    let usertype;
    let storeOwnerParams = {};
    const usercredentials = getOfflineData("user");
    if (usercredentials && usercredentials.type === userTypes.STORE_OWNER) {
      storeOwnerParams = { storeOwner: usercredentials.id };
      usertype = userTypes.STORE_OWNER;
    }
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.DASHBOARD}`, null, {
      startDate: startDate,
      endDate: endDate,
      ...storeOwnerParams,
    })
      .then((response) => {
        if (response) {
          setshowAllData(false);
          setTimeout(() => {
            setshowAllData(true);
          }, 150);
          let overviewObj = {};
          overviewObj["total"] = response.overviewStats?.totalTickets;
          overviewObj["reOpen"] = response.overviewStats?.reopenedTickets;
          overviewObj["open"] = response.overviewStats?.openTicketCount;
          overviewObj["raisedCount"] = response.overviewStats?.last30DayCount;
          overviewObj["topIssueCausingStore"] =
            response.overviewStats?.topIssueCausingStore?.length > 0
              ? response.overviewStats.topIssueCausingStore[0]
              : null;
          overviewObj["topIssueCausingDept"] =
            response.overviewStats?.topIssueCausingDept?.length > 0
              ? response.overviewStats.topIssueCausingDept[0]
              : null;
          overviewObj["topIssueCausingCategory"] =
            response.overviewStats?.topIssueCausingCategory?.length > 0
              ? response.overviewStats.topIssueCausingCategory[0]
              : null;
          overviewObj["topPerformer"] =
            response.overviewStats?.topPerformer?.length > 0
              ? response.overviewStats.topPerformer[0]
              : null;
          overviewObj["mttc"] = response.overviewStats?.mttc;
          overviewObj["mttr"] = response.overviewStats?.mttr;
          overviewObj["topSLABreachedDept"] =
            response.overviewStats?.topSLABreachedDept?.length > 0
              ? response.overviewStats.topSLABreachedDept[0]
              : null;
          overviewObj["monthWiseTicketCounts"] = response.monthWiseTicketCounts;
          overviewObj["withoutResolution"] =
            response.overviewStats?.unresolvedTicketsByX;

          let deptAndCategoryWiseStats =
            response.deptAndCategoryWiseStats?.length > 0
              ? response.deptAndCategoryWiseStats[0]
              : null;
          const tempStore =
            response.storeWise?.length > 0 ? response.storeWise : [];

          if (usertype !== userTypes.STORE_OWNER) {
            setDashboardDetails((prevDashboardDetails) => ({
              overview: { ...prevDashboardDetails.overview, ...overviewObj },
              deptAndCategory: {
                ...prevDashboardDetails.deptAndCategory,
                ...deptAndCategoryWiseStats,
              },
              store: tempStore,
              mtt: {
                mttr: response?.mttrList?.length > 0 ? response.mttrList : [],
                mttc: response?.mttcList?.length > 0 ? response.mttcList : [],
              },
              sla:
                response.slaCounts?.escalationStats?.length > 0
                  ? response.slaCounts.escalationStats
                  : [],
              support: response.supportPersonStats,
            }));
          } else {
            setDashboardDetails((prevDashboardDetails) => ({
              overview: { ...prevDashboardDetails.overview, ...overviewObj },
              deptAndCategory: {
                ...prevDashboardDetails.deptAndCategory,
                ...deptAndCategoryWiseStats,
              },
              store: tempStore,
            }));
          }
        }
      })
      .catch((err) => console.log(err));
  }, [startDate, endDate]);

  return (
    <>
      <div>
        <div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="tab-container"
          >
            <Grid container spacing={0}>
              <Grid item md={8} xs={12} sm={7} className="custom-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  scrollButtons
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  <Tab label="Overview" />
                  <Tab label="Department & Category" />
                  {!showSelectedTabs && <Tab label="Support Person" />}
                  <Tab label="Store" />
                  {!showSelectedTabs && <Tab label="SLA Breaches" />}
                  {!showSelectedTabs && <Tab label="Time Related" />}
                </Tabs>
              </Grid>
              <Grid item md={4} xs={12} sm={5} className="date-container">
                <div className="dateFilter">
                  <DateRangePicker
                    showLeadingZeros={true}
                    clearIcon=""
                    calendarClassName="task-created-range"
                    onChange={setDatePickerDate}
                    value={[datePickerstartDate, datePickerendDate]}
                  />
                  <Button
                    className="apply"
                    variant="contained"
                    color="primary"
                    onClick={applyDatePickerDate}
                  >
                    Apply
                  </Button>
                  <HighlightOffIcon
                    onClick={clearDateRangeFilter}
                    className="clearicon"
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <div className="dashboard-body">
        {showAllData && (
          <div>
            <TabPanel value={value} index={0}>
              <Overview data={dashboardDetails.overview} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DepartmentAndCategory data={dashboardDetails.deptAndCategory} />
            </TabPanel>

            {!showSelectedTabs && (
              <TabPanel value={value} index={2}>
                <SupportPerson data={dashboardDetails.support} />
              </TabPanel>
            )}

            <TabPanel value={value} index={storeIndex}>
              <Store data={dashboardDetails.store} />
            </TabPanel>

            {!showSelectedTabs && (
              <>
                <TabPanel value={value} index={4}>
                  <SlaBreaches
                    toggle={toggle}
                    setToggle={setToggle}
                    data={dashboardDetails.sla ? dashboardDetails.sla : []}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <TimeRelated data={dashboardDetails.mtt} />
                </TabPanel>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
