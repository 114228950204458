import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LOGO from "../../resources/images/logo.png";
import { Login } from "../login";

const Header = () => {
  const history = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  return (
    <div className="header-con">
      <div className="header-content">
        <div className="logo-con">
          <img src={LOGO} className="logo" />
          <span>Helpdesk</span>
        </div>
        <Button
          onClick={() => {
            setShowLogin(true);
          }}
          variant="contained"
        >
          Sign In
        </Button>
      </div>
      {showLogin && <Login setShowLogin={setShowLogin} />}
    </div>
  );
};

export default Header;
