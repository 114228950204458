export const getFormConfig = (
  ticketDetails, storeList, ticketCategoriesList, departmentsList,subCategoryList, errors
  ) => {
    return [
      {
        label: 'Stores',
        name: 'StoresList',
        type: 'select',
        value: ticketDetails.StoresList,
        error: errors.storeslist,
        options: storeList,
        disabled: false,
        helperText: errors.storeslist ? 'Required' : '',
      },
      {
        label: 'Departments',
        name: 'department',
        type: 'select',
        value: ticketDetails.department,
        error: errors.department,
        options: departmentsList,
        disabled: false,
        helperText: errors.department ? 'Required' : '',
      },
      {
        label: 'Ticket Category',
        name: 'ticketcategory',
        type: 'select',
        value: ticketDetails.ticketcategory,
        error: errors.category,
        options: ticketCategoriesList,
        disabled: false,
        helperText: errors.category ? 'Required' : '',
      },
      {
        label: 'Ticket Sub Category',
        name: 'ticketsubcategory',
        type: 'select',
        value: ticketDetails.ticketsubcategory,
        error: errors.ticketsubcategory,
        options: subCategoryList,
        disabled: false,
        helperText: errors.subCategoryList ? 'Required' : '',
      },
      {
        label: 'Details',
        name: 'details',
        type: 'text',
        value: ticketDetails.details,
        error: errors.details,
        disabled: false,
        helperText: errors.details ? 'Required' : '',
      }
    ];
  };
  
  export const isFormValid = (ticketDetails) => {
    let errors = {};
    const {StoresList, department, details, ticketcategory} = ticketDetails
    if (!StoresList) {
      errors.storeslist = true
      
    }
    if (!department) {
      errors.department = true
    }
    if (!details) {
      errors.details = true
    }
    if (!ticketcategory) {
      errors.category = true
    }
    return errors;
  };

  export const convertedEventNamesToErrorObjectKeys = {
    StoresList:"storeslist",
    department:"department",
    details:"details",
    ticketcategory:"category"
  }