import React, { useState, useEffect } from "react";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Select from "react-select";
import { Button } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { getHeaderConfig } from "./helper";
import { lastMonthDate } from "./tickets";
import SiTable from "../../core/table";
import { exportToExcel } from "../../utils";
const SupportPerson = () => {
  const [reportData, setReportData] = useState({});
  const [supportPerson, setSupportPerson] = useState({});
  const [serviceEngineers, setServiceEngineers] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 12,
    sortBy: "-createdAt",
  });
  const [dateRange, setDateRange] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
  });

  const [filterAfterClickingOnApply, setFilterAfterClickingOnApply] = useState({
    startDate: lastMonthDate(),
    endDate: new Date().toISOString(),
    assignedTo: null,
    supportPersonLabel: "",
  });
  useEffect(() => {
    otherOptions();
  }, []);
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const fetchDataHandler = (filter, getDataForPreviouslyAppliedFilter) => {
    const params = {
      ...filter,
      startDate: dateRange.startDate
        ? new Date(dateRange.startDate).toUTCString()
        : null,
      endDate: dateRange.endDate
        ? new Date(dateRange.endDate).toUTCString()
        : null,
      assignedTo: supportPerson.key,
      sortBy: "-createdAt",
    };
    if (getDataForPreviouslyAppliedFilter) {
      params.startDate = filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null;
      params.endDate = filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate).toUTCString()
        : null;
      params.assignedTo = filterAfterClickingOnApply.assignedTo;
    }
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params
    )
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadReport = async () => {
    const params = {
      limit: reportData.totalResults,
      page: 1,
      startDate: filterAfterClickingOnApply.startDate
        ? new Date(filterAfterClickingOnApply.startDate).toUTCString()
        : null,
      endDate: filterAfterClickingOnApply.endDate
        ? new Date(filterAfterClickingOnApply.endDate)
        : null,
      assignedTo: filterAfterClickingOnApply.assignedTo,
    };
    let response = await invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.TICKET_REPORTS}`,
      null,
      params
    );
    let results = [];
    if (response && response.results) {
      results = response.results.map((item) => {
        const Status =
          item?.status.slice(0, 1).toUpperCase() +
          item?.status.slice(1).toLowerCase();
        const {
          id,
          sla,
          reopened: Reopened,
          closed: Closed,
          resolveMessage,
          holdEscalations,
          dept,
          category,
          store,
          assignedTo,
          severity: Severity,
        } = item;
        return {
          "Ticket Id": id,
          Status,
          SLA: sla,
          Assignee: assignedTo?.name,
          Reopened: Reopened ? "Yes" : "No",
          Category: category?.name,
          Closed: Closed ? "Yes" : "No",
          "Resolved Message": resolveMessage,
          "Hold Escalations": holdEscalations ? "Yes" : "No",
          Department: dept?.name,
          Store: store?.name,
          Severity: Severity
            ? Severity.slice(0, 1).toUpperCase() +
              Severity.slice(1).toLowerCase()
            : "",
        };
      });
    }
    exportToExcel(results, filterAfterClickingOnApply.supportPersonLabel);
  };

  const otherOptions = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.ADMINANDTECHNETUSERSLIST}`
    )
      .then((response) => {
        if (response) {
          setServiceEngineers(
            response.map((r) => {
              return {
                ...r,
                label: r.name,
                value: r.id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="reports-table">
      <div className="filter-section">
        <div className="left-s">
          <div>
            <div>Support Person</div>
            <Select
              value={supportPerson}
              classNamePrefix="si-select"
              options={serviceEngineers}
              onChange={(option) => {
                setSupportPerson((prevVal) => ({
                  ...prevVal,
                  key: option.id,
                  label: option.name,
                }));
              }}
            />
          </div>
          {supportPerson.key && (
            <div className="left-s">
              <div>
                <DateRangePicker
                  cleanable={false}
                  placeholder="Select Date Range"
                  onChange={dateRangeHandler}
                />
              </div>
              <Button
                size="medium"
                variant="contained"
                onClick={() => {
                  setFilterAfterClickingOnApply({
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    assignedTo: supportPerson?.key,
                    supportPersonLabel: supportPerson?.label,
                  });
                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  fetchDataHandler({ ...filters, page: 1 });
                }}
              >
                Apply
              </Button>
            </div>
          )}
        </div>

        {supportPerson.key && (
          <Button
            size="large"
            variant="contained"
            onClick={downloadReport}
            disabled={(reportData.results || []).length === 0}
          >
            Download reports
          </Button>
        )}
      </div>
      <SiTable
        header={getHeaderConfig()}
        filters={filters}
        data={reportData.results || []}
        pageCount={reportData.totalPages}
        onChange={(e, page) => {
          setFilters({
            ...filters,
            page,
          });
          fetchDataHandler(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></SiTable>
      {!supportPerson.key && (
        <span className="user-info-msg">
          Please select the Support Person to download reports
        </span>
      )}
    </div>
  );
};

export default SupportPerson;
