import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import UserMenu from "./user-menu";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LOGO from "../../resources/images/logo.png";
import { getOfflineData } from "../../utils/offline-services";
import { userTypes } from "../../utils/constants";
const SideMenu = ({ options, onMenuCLick, showReportIcon }) => {
  const [activeMenu, setActiveMenu] = useState("/");
  const navigate = useNavigate();
  const user = getOfflineData("user");
  useEffect(() => {
    setActiveMenu(
      options.find((m) => m.url === window.location.pathname) || {}
    );
  }, [options]);

  return (
    <div className="side-menu-container">
      <img src={LOGO} alt="SUBWAY" className="logo"></img>
      <div className="menu-items">
        {options.map((menu, index) => {
          let showMenuOption = true;
          return (
            <>
              {showMenuOption ? (
                <div
                  key={menu.value}
                  className={
                    menu.value === activeMenu.value
                      ? "menu-item menu-item-active"
                      : "menu-item"
                  }
                  onClick={() => {
                    onMenuCLick();
                    setActiveMenu(menu);
                    navigate(menu.url);
                  }}
                >
                  <div className="menu-name align-center">
                    {index === 0 && <DashboardIcon />}
                    {index === 1 && <ConfirmationNumberIcon />}
                    {user &&
                      user.type !== userTypes.STORE_OWNER &&
                      index === 2 && <SettingsIcon />}
                    {index === 3 && <SummarizeIcon />}
                    <span className="sidebarmenulabel">{menu.label}</span>
                  </div>
                </div>
              ) : (
                <Fragment key={menu.value}></Fragment>
              )}
            </>
          );
        })}
      </div>
      <div className="logout-section si-hide-mobile">
        <UserMenu />
      </div>
    </div>
  );
};

export default SideMenu;
