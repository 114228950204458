import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./index.scss";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import {
  getFormConfig,
  isFormValid,
  convertedEventNamesToErrorObjectKeys,
} from "./helper";
import SiFileUpload from "../../core/file-uploader";
import Select from "react-select";
import { invokeUploadFile } from "../../utils/http-service";
import { getAllTicketCategoriesForaParent } from "./data-apis";
const sx = {
  input: {
    background: "#fff",
    borderColor: "gray",
  },
};

export const CreateTicket = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [storeList, setStoresList] = useState([]);
  const [ticketCategoriesList, setticketCategoriesList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [departmentsList, setdepartmentsList] = useState([]);

  const [ticketDetails, setTicketDetails] = useState({});
  const [multipleFiles, setMultipleFiles] = useState([]);

  useEffect(() => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_STORE_NAMES}`,
      null
    )
      .then((response) => {
        if (response) {
          const listOfStores = response.map((item) => {
            const { name, id } = item;
            return { label: name, value: id };
          });
          setStoresList(listOfStores);
          // setTicketDetails((alldetails) => ({ ...alldetails, storeList: listOfStores }))
        }
      })
      .catch((err) => console.log(err));
  }, [setdepartmentsList, setStoresList]);

  const loadAllDepartmentsAssociatedWithStore = (storeId) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_ALL_DEPARTMENTs_STOREWISE}${storeId}`,
      null
    )
      .then((response) => {
        if (response && response.departments) {
          const departmentsList = response.departments.map((item) => {
            const { id, name } = item;
            return { label: name, value: id };
          });
          setdepartmentsList(departmentsList);
        } else {
          setdepartmentsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setdepartmentsList([]);
      });
  };

  const getTicketCategoriesByCurrentDepartment = (dept, parentNull) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.LIST_CATEGORY_DEPARTMENT_WISE}${dept}`,
      null,
      { showZeroEscalation: true, parentNull: parentNull ? true : false }
    )
      .then((response) => {
        if (!response?.message) {
          const ticketCategoriesList = response.map((item) => {
            const { name, id } = item;
            return { label: name, value: id };
          });
          setticketCategoriesList(ticketCategoriesList);
        }
      })
      .catch((err) => console.log(err));
  };

  const onInputChange = (event) => {
    const isDepartmentDropDown = event.target.name === "department";
    const isStoreChanged = event.target.name === "StoresList";
    const isParentCategorySelected = event.target.name === "ticketcategory";
    let valueToRemoveOnResetting = {};
    if (isParentCategorySelected) {
      const parentValue = event?.target?.value?.value;
      const isValueSelected = !!parentValue;
      if (isValueSelected) {
        getAllTicketCategoriesForaParent(parentValue, setSubCategoryList);
      }
      valueToRemoveOnResetting.ticketsubcategory = null;
    }
    // When department is resetted ticket category also has to be resetted as per department
    if (isDepartmentDropDown || isStoreChanged) {
      valueToRemoveOnResetting = {
        ticketcategory: null,
        ticketsubcategory: null,
      };
      if (isStoreChanged) {
        valueToRemoveOnResetting.department = null;
      }
      setSubCategoryList([]);
    }

    setTicketDetails({
      ...ticketDetails,
      ...valueToRemoveOnResetting,
      [event.target.name]: event.target.value,
    });

    if (isDepartmentDropDown) {
      getTicketCategoriesByCurrentDepartment(event.target.value.value, true);
    }

    const errorKey = convertedEventNamesToErrorObjectKeys[event.target.name];
    const removeError = {};
    removeError[errorKey] = false;
    setErrors((prevError) => ({ ...prevError, ...removeError }));
    if (event.target.name === "StoresList") {
      loadAllDepartmentsAssociatedWithStore(event.target.value.value);
    }
  };

  const createTicketApi = (payload) => {
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.CREATECOMPLAINT}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.error("Failed to create ticket");
        } else {
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const createTicket = () => {
    let allerrors = isFormValid(ticketDetails);
    if (Object.keys(allerrors).length > 0) {
      setErrors(allerrors);
      return;
    }
    const {
      StoresList,
      department,
      details,
      ticketcategory,
      ticketsubcategory,
    } = ticketDetails;
    const category = ticketsubcategory?.value
      ? parseInt(ticketsubcategory?.value)
      : parseInt(ticketcategory.value);

    let payload = {
      store: parseInt(StoresList.value),
      dept: parseInt(department.value),
      category,
      details,
    };
    if (multipleFiles.length > 0) {
      const formData = new FormData();
      let sizeError = false;
      multipleFiles.forEach((item) => {
        if (item.size > 1000000) {
          sizeError = true;
        }
        formData.append("upload", item);
      });

      if (sizeError) {
        toast.error("File size should not exceed 1MB");
        return;
      }

      return invokeUploadFile(`${HOSTNAME}${REST_URLS.UPLOAD_FILES}`, formData)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            payload["attachments"] = response.data;
            createTicketApi(payload);
          } else {
            toast.error(
              (response.data && response.data.message) || "Upload Failed"
            );
          }
        })
        .catch((err) => {
          toast.error("Upload Failed");
        });
    }
    createTicketApi(payload);
  };

  const inputElements = (config) => {
    return (
      <div className="form-row" key={config.label}>
        <span className="label">{config.label}</span>
        {config.type === "select" ? (
          <div>
            <Select
              placeHolder="Name"
              options={config.options}
              value={config.value}
              onChange={(value) => {
                onInputChange({
                  target: {
                    name: config.name,
                    value: value,
                  },
                });
              }}
              isDisabled={config.disabled}
            />
            <div className="danger-text"> {config.helperText}</div>
          </div>
        ) : (
          <TextField
            size="small"
            value={config.value}
            name={config.name}
            type={config.type}
            sx={{ ...sx, width: "100%" }}
            onChange={onInputChange}
            error={config.error}
            helperText={config.error ? "Required" : ""}
            multiline
            rows={10}
          />
        )}
      </div>
    );
  };

  return (
    <div className="create-tickets">
      <div className="back-container">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
        </IconButton>
        <span className="s-heading">New Ticket</span>
      </div>
      <div className="create-ticket-form">
        {getFormConfig(
          ticketDetails,
          storeList,
          ticketCategoriesList,
          departmentsList,
          subCategoryList,
          errors
        ).map((config) => {
          return inputElements(config);
        })}

        <SiFileUpload
          filesLimit={5}
          setMultipleFilesFunction={setMultipleFiles}
          multiple={true}
        />

        <div className="buttons">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/")}
          >
            Go Back
          </Button>
          <Button variant="contained" color="primary" onClick={createTicket}>
            Create Ticket
          </Button>
        </div>
      </div>
    </div>
  );
};
