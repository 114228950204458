import SideMenu from "../core/side-menu";
import Header from "../core/header";
import "./index.scss";
import { SIDE_MENU } from "../core/side-menu/config";
import { getOfflineData } from "../utils/offline-services";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BellCorpStudioLogoContainer } from "../components/Bellcorp-Studio-Logo";

const MainContainer = ({ children }) => {
  const [showSideMenu, changeviewSettingsForSideMenu] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = getOfflineData("user");
    const { pathname } = location;

    if (user) {
      const { type } = user;
      if (type === "STORE_OWNER") {
        // const validPathForStoreUser = pathname === '/tickets' || pathname === '/create' || pathname.includes('details')
        const invalidPathForUser = pathname.includes("report");
        changeviewSettingsForSideMenu(false);
        if (invalidPathForUser) {
          navigate("/tickets");
        }
      } else {
        changeviewSettingsForSideMenu(true);
        if (pathname === "create") {
          navigate("/tickets");
        }
      }
    }
  }, [location, navigate]);

  return (
    <>
      <div className="si-hide-web mobile-header-container">
        <Header />
      </div>
      <div className="main-container">
        <div className="main-left-container si-hide-mobile">
          <SideMenu
            showReportIcon={showSideMenu}
            options={SIDE_MENU()}
            onMenuCLick={() => {}}
          />
        </div>

        <div className="main-right-container">
          <div>{children}</div>
          <BellCorpStudioLogoContainer />
        </div>
      </div>
    </>
  );
};

export default MainContainer;
